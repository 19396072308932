//@flow

export function append(parentTag: string, childTag: string, child: *) {
  const wrapper = document.body?.querySelector(parentTag);
  const old = wrapper?.querySelector(childTag);
  if (old) {
    wrapper?.replaceChild(child, old);
  } else {
    wrapper?.appendChild(child);
  }
}
declare export default function dom(
  type: 'fragment',
  props: null,
  ...children: Array<any>
): DocumentFragment;
declare export default function dom(
  type: string,
  props: *,
  ...children: Array<any>
): HTMLElement;

export default function dom(
  type: string,
  props: {[string]: any} | null,
  ...children: Array<any>
) {
  let dom;
  if (type === 'fragment') {
    dom = document.createDocumentFragment();
  } else {
    dom = document.createElement(type);
    if (props) {
      assignProps(dom, props);
    }
  }

  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    if (typeof child !== 'string' && typeof child !== 'number')
      dom.appendChild(child);
    else dom.appendChild(document.createTextNode(String(child)));
  }
  return dom;
}
function assignProps(dom, props) {
  for (const key in props) {
    const prop = props[key];
    switch (key) {
      case 'class':
      case 'className':
        dom.classList.add(...String(prop).split(/ /gi));
        break;
      case 'style':
        if (typeof prop === 'string') {
          dom.style = prop;
        } else {
          //$off
          dom.style = css(prop);
        }
        break;
      default:
        if (key.startsWith('on')) {
          //$off
          dom[key] = prop;
        } else {
          dom.setAttribute(key, String(prop));
        }
    }
  }
}

const css = (() => {
  function decamelcase(word) {
    return word.replace(/[A-Z]+/g, (ch) => `-${ch.toLocaleLowerCase()}`);
  }
  function normalizeCssObject(obj) {
    const result = {};
    for (const key in obj) {
      result[decamelcase(key)] = String(obj[key]);
    }
    return result;
  }
  function parseCssObject(obj) {
    const pairs = [];
    for (const key in obj) {
      pairs.push(`${key}: ${String(obj[key])};`);
    }
    return pairs.join(' ');
  }
  function css(obj) {
    return parseCssObject(normalizeCssObject(obj));
  }
  return css;
})();
