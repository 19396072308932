//@flow
//@jsx dom
//@jsxFrag 'fragment'
import anime from 'animejs';
import {createStore, createEvent, forward} from 'effector';
import dom, {append} from './dom';

const DURATION = 700;

const handlers = {
  email(e: FocusEvent) {
    if (current) current.pause();
    current = anime({
      targets: 'path',
      strokeDashoffset: {
        value: 0,
        duration: DURATION,
        easing: 'easeOutQuart',
      },
      strokeDasharray: {
        value: '240 1386',
        duration: DURATION,
        easing: 'easeOutQuart',
      },
    });
  },
  password(e: FocusEvent) {
    if (current) current.pause();
    current = anime({
      targets: 'path',
      strokeDashoffset: {
        value: -336,
        duration: DURATION,
        easing: 'easeOutQuart',
      },
      strokeDasharray: {
        value: '240 1386',
        duration: DURATION,
        easing: 'easeOutQuart',
      },
    });
  },
  submit(e: FocusEvent) {
    if (current) current.pause();
    current = anime({
      targets: 'path',
      strokeDashoffset: {
        value: -730,
        duration: DURATION,
        easing: 'easeOutQuart',
      },
      strokeDasharray: {
        value: '530 1386',
        duration: DURATION,
        easing: 'easeOutQuart',
      },
    });
  },
};

export const status = document.createTextNode('...');

const button = (
  <a
    id="submit"
    class="text"
    href="https://stage.zerobias.net/oauth/authorize"
    onclick={(e) => {
      // e.preventDefault();
      upd({username: 'Bias'});
      handlers.submit(e);
    }}
  >
    {status}
  </a>
);
const upd = createEvent('update status');
const user = createStore(null);
forward({
  from: upd,
  to: user,
});
user
  .map((u) => (u ? `user: ${String(u.username)}` : 'login'))
  .watch((s) => {
    status.replaceData(0, status.length, s); // = s;
  });

append(
  '.container',
  '.left',
  <div class="left">
    <div class="left--white">
      <div class="login">Zero Bias</div>
    </div>
  </div>,
);

// append(
//   '.right',
//   '.form',
//   <form class="form">
//     <label for="email">Email</label>
//     <input type="email" id="email" onfocus={handlers.email} />
//     <label for="password">Password</label>
//     <input type="password" id="password" onfocus={handlers.password} />
//     <input type="submit" id="submit" value="Submit" onfocus={handlers.submit} />
//   </form>,
// );

append('.right', '.text', button);

let current = null;
